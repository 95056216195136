// This file autogenerated by generate-asset-types.js
    
    export const ALL_SOUNDS: string[] = [
        require("./audio/dialog/dialog_helmet_headed_out.flac"),
  require("./audio/dialog/dialog_helmet_im_hungry.flac"),
  require("./audio/dialog/dialog_helmet_its_cold.flac"),
  require("./audio/dialog/dialog_helmet_love_to_see_it.flac"),
  require("./audio/dialog/dialog_helmet_mmhmm.flac"),
  require("./audio/dialog/dialog_helmet_pain1.flac"),
  require("./audio/dialog/dialog_helmet_pain2.flac"),
  require("./audio/dialog/dialog_helmet_pain3.flac"),
  require("./audio/dialog/dialog_helmet_pain4.flac"),
  require("./audio/dialog/dialog_helmet_pain5.flac"),
  require("./audio/dialog/dialog_helmet_pain6.flac"),
  require("./audio/dialog/dialog_helmet_pain7.flac"),
  require("./audio/dialog/dialog_helmet_right.flac"),
  require("./audio/dialog/dialog_helmet_stove.flac"),
  require("./audio/dialog/dialog_helmet_sure_thing.flac"),
  require("./audio/dialog/dialog_helmet_ugh.flac"),
  require("./audio/dialog/dialog_radio_headed_out.flac"),
  require("./audio/dialog/dialog_radio_im_hungry.flac"),
  require("./audio/dialog/dialog_radio_its_cold.flac"),
  require("./audio/dialog/dialog_radio_love_to_see_it.flac"),
  require("./audio/dialog/dialog_radio_mmhmm.flac"),
  require("./audio/dialog/dialog_radio_pain1.flac"),
  require("./audio/dialog/dialog_radio_pain2.flac"),
  require("./audio/dialog/dialog_radio_pain3.flac"),
  require("./audio/dialog/dialog_radio_pain4.flac"),
  require("./audio/dialog/dialog_radio_pain5.flac"),
  require("./audio/dialog/dialog_radio_pain6.flac"),
  require("./audio/dialog/dialog_radio_pain7.flac"),
  require("./audio/dialog/dialog_radio_right.flac"),
  require("./audio/dialog/dialog_radio_stove.flac"),
  require("./audio/dialog/dialog_radio_sure_thing.flac"),
  require("./audio/dialog/dialog_radio_ugh.flac"),
  require("./audio/diver/breathe_in_1.flac"),
  require("./audio/diver/breathe_in_2.flac"),
  require("./audio/diver/breathe_out_1.flac"),
  require("./audio/diver/breathe_out_2.flac"),
  require("./audio/diver/dead.flac"),
  require("./audio/diver/flashlight-off.flac"),
  require("./audio/diver/flashlight-on.flac"),
  require("./audio/diver/glow-stick-crack-1.flac"),
  require("./audio/diver/oww.flac"),
  require("./audio/fish/electrocution.flac"),
  require("./audio/fish/shark-miss.flac"),
  require("./audio/fish/sharkbite.flac"),
  require("./audio/impacts/flesh-hit-1.flac"),
  require("./audio/impacts/flesh-hit-2.flac"),
  require("./audio/impacts/flesh-hit-3.flac"),
  require("./audio/impacts/flesh-hit-4.flac"),
  require("./audio/impacts/glow-stick-drop-1.flac"),
  require("./audio/impacts/glow-stick-drop-2.flac"),
  require("./audio/impacts/harpoon_hit_ground_1.flac"),
  require("./audio/impacts/metal_hitting_rock.flac"),
  require("./audio/impacts/splash.flac"),
  require("./audio/misc/pop1.flac"),
  require("./audio/misc/ringring.flac"),
  require("./audio/misc/victory.flac"),
  require("./audio/music-and-ambience/above_water_music.flac"),
  require("./audio/music-and-ambience/ocean_texture.flac"),
  require("./audio/music-and-ambience/spooky_sinking.flac"),
  require("./audio/ui/bell_positive_1.flac"),
  require("./audio/ui/bell_positive_2.flac"),
  require("./audio/ui/bell_tension_resolve.flac"),
  require("./audio/ui/bell_tension.flac"),
  require("./audio/ui/bell_tritone.flac"),
  require("./audio/ui/bought_upgrade.flac"),
  require("./audio/ui/ding.flac"),
  require("./audio/ui/got_item_or_upgrade_or_objective.flac"),
  require("./audio/ui/musical_nope.flac"),
  require("./audio/weapons/reel_in_harpoon.flac"),
  require("./audio/weapons/smallweapon1.flac"),
  require("./audio/weapons/smallweapon2.flac"),
  require("./audio/weapons/smallweapon3.flac"),
  require("./audio/weapons/smallweapon4.flac"),
  require("./audio/weapons/smallweaponalt_1.flac"),
  require("./audio/weapons/smallweaponalt_2.flac"),
  require("./audio/weapons/smallweaponalt_3.flac"),
  require("./audio/weapons/smallweaponalt_4.flac"),
  require("./audio/weapons/spear1.flac"),
  require("./audio/weapons/spear2.flac"),
  require("./audio/weapons/spear3.flac"),
  require("./audio/weapons/spear4.flac"),
    ];
    
    export const ALL_IMAGES: string[] = [
        require("./favicon.png"),
  require("./images/diver/diver_left.png"),
  require("./images/diver/diver_right.png"),
  require("./images/diver/diver.png"),
  require("./images/diver/harpoon.png"),
  require("./images/environment/background.png"),
  require("./images/environment/boat.png"),
  require("./images/environment/phone.png"),
  require("./images/environment/sky.png"),
  require("./images/environment/water-overlay.png"),
  require("./images/fish/angler_1.png"),
  require("./images/fish/clownfish.png"),
  require("./images/fish/eel-body-1.png"),
  require("./images/fish/eel-head-1.png"),
  require("./images/fish/jellyfish_1.png"),
  require("./images/fish/jellyfish_2.png"),
  require("./images/fish/puffer_spine.png"),
  require("./images/fish/puffer0.png"),
  require("./images/fish/puffer1.png"),
  require("./images/fish/puffer2.png"),
  require("./images/fish/puffer3.png"),
  require("./images/fish/puffer4.png"),
  require("./images/fish/shark1.png"),
  require("./images/fish/shark2.png"),
  require("./images/fish/shark3.png"),
  require("./images/fish/squid-1.png"),
  require("./images/fish/squid-2.png"),
  require("./images/fish/squid-3.png"),
  require("./images/fish/sting_ray_1.png"),
  require("./images/fish/sting_ray_2.png"),
  require("./images/flora/anemone-1.png"),
  require("./images/flora/anemone-2.png"),
  require("./images/flora/anemone-3.png"),
  require("./images/flora/anemone-4.png"),
  require("./images/flora/seaweed-1.png"),
  require("./images/lights/daylight.png"),
  require("./images/lights/flashlight-overlay.png"),
  require("./images/lights/point-light.png"),
  require("./images/particles/blood-1.png"),
  require("./images/particles/blood-2.png"),
  require("./images/particles/blood-3.png"),
  require("./images/particles/bubble.png"),
  require("./images/particles/glow-stick-1.png"),
  require("./images/particles/glow-stick-2.png"),
  require("./images/particles/glow-stick-3.png"),
  require("./images/particles/pickup-1.png"),
  require("./images/particles/pickup-2.png"),
  require("./images/particles/pickup-3.png"),
  require("./images/particles/pickup-4.png"),
  require("./images/particles/pickup-5.png"),
  require("./images/particles/pickup-6.png"),
  require("./images/particles/pickup-7.png"),
  require("./images/particles/water-splash.png"),
  require("./images/tiles/reference-tileset-2.png"),
  require("./images/tiles/reference-tileset-3.png"),
  require("./images/tiles/reference-tileset-4.png"),
  require("./images/tiles/reference-tileset-numbered.png"),
  require("./images/tiles/reference-tileset.png"),
  require("./images/tiles/stone_tiles.png"),
  require("./images/tiles/stone_tiles2.png"),
  require("./images/tiles/stone-tilesset-1.png"),
  require("./images/tiles/tile.png"),
  require("./images/tiles/tileset_reference.png"),
  require("./images/ui/dive-watch-back.png"),
  require("./images/ui/dive-watch-needle.png"),
  require("./images/ui/sonar-tile.png"),
  require("./images/ui/stone_tiles3.png"),
    ];
    