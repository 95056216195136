import React from "react";
import "./Credits.css";

export function Credits() {
  return (
    <div className="Credits">
      <div>
        <h1>Mariana</h1>

        <h2>Game Design</h2>
        <span>Simon</span>
        <span>Parker</span>
        <span>Jiaaro</span>
        <span>Socrates</span>
        <span>Alpaca</span>

        <h2>Programming</h2>
        <span>Simon</span>
        <span>Parker</span>
        <span>WonkMart</span>
        <span>Philip</span>

        <h2>Art</h2>
        <span>Lawlfrats</span>
        <span>Happy Hippy</span>
        <span>Simon</span>

        <h2>Sound</h2>
        <span>Jiaaro</span>
        <span>Simon</span>
        <span>Zapsplat.com</span>

        <h2>Music</h2>
        <span>Jiaaro</span>

        <h2>Special Thanks</h2>
      </div>
    </div>
  );
}
